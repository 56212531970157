/* You can add global styles to this file, and also import other style files */
p {
  font-size: 80px;
  margin: 0;
}

#bodyStyle {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2.3em;
}

.tickerContainer {
  overflow-x: hidden;
  overflow-y: scroll;
  white-space: nowrap;
}

#ghost {
  display: inline-block;
  height: 0;
  position: absolute;
}
